<template>
  <v-navigation-drawer absolute permanent class="grey lighten-1">
    <v-list dense nav class="pa-0 pt-3">
      <!-- <v-list-item>
        <v-list-item-content>
          <v-text-field
            solo
            rounded
            filled
            dense
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-list-item-content>
      </v-list-item> -->

      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content class="">
            <v-list-item-title>Vocabularies</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-if="$route.name != 'Create'">
          <!-- <v-avatar color="pink" size="30" class="mr-8">+</v-avatar> -->
          <v-list-item-icon class="d-flex align-center">
            <v-btn fab outlined x-small @click="addBtnPressed('vocabularies')">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title @click="addBtnPressed('vocabularies')">
              {{ getWords("side_add") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-for="(item, i) in dataSet.vocabularies" :key="i">
          <v-avatar v-if="isSelectedV(item)" color="blue" size="30" class="mr-8">v</v-avatar>
          <v-avatar v-else color="pink" size="30" class="mr-8">v</v-avatar>
          <v-list-item-content>
            <v-list-item-title
              @click="editBtnPressed"
              :id="item['dcat:identifier']"
              active-class="highlighted"
            >
              {{ getTitle(item) }}
            </v-list-item-title>
          </v-list-item-content>
          <!--
          <v-list-item-icon class="ma-0">
            <v-menu offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-card min-width="200">
                <v-list>
                  <v-list-item>
                    <v-list-item-title
                      @click="editBtnPressed"
                      :id="item['dcat:identifier']"
                      >編集
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>表示</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>削除</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </v-list-item-icon> -->
        </v-list-item>
      </v-list-group>

      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content class="">
            <v-list-item-title>Schemas</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item>
          <v-list-item-icon class="d-flex align-center">
            <v-btn fab outlined x-small>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ getWords("side_add") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-for="item in dataSet.schemas" :key="item.title">
          <v-avatar color="pink" size="30" class="mr-8">s</v-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon class="ma-0">
            <v-menu offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-card min-width="200">
                <v-list>
                  <v-list-item>
                    <v-list-item-title>編集</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>表示</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>削除</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>

      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content class="">
            <v-list-item-title>Terms</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item>
          <v-list-item-icon class="d-flex align-center">
            <v-btn fab outlined x-small>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ getWords("side_add") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-for="item in dataSet.terms" :key="item.title">
          <v-avatar color="pink" size="30" class="mr-8">t</v-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon class="ma-0">
            <v-menu offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-card min-width="200">
                <v-list>
                  <v-list-item>
                    <v-list-item-title>編集</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>表示</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>削除</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>

      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Name Spaces</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-if="$route.name != 'NameSpacesCreate'">
          <v-list-item-icon class="d-flex align-center">
            <v-btn fab outlined x-small @click="addBtnPressed('namespaces')">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title @click="addBtnPressed('namespaces')">
              {{ getWords("side_add") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-for="item in dataSet.namespaces" :key="item.id">
          <v-avatar v-if="isSelectedN(item)" color="blue" size="30" class="mr-8">n</v-avatar>
          <v-avatar v-else color="pink" size="30" class="mr-8">n</v-avatar>
          <v-list-item-content>
            <v-list-item-title @click="editNBtnPressed" :id="item.id">
              {{ item.path }}
            </v-list-item-title>
          </v-list-item-content>
          <!-- <v-list-item-icon class="ma-0">
            <v-menu offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-card min-width="200">
                <v-list>
                  <v-list-item>
                    <v-list-item-title>編集</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>表示</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>削除</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </v-list-item-icon> -->
        </v-list-item>
      </v-list-group>

      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Organizations</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item>
          <v-list-item-icon class="d-flex align-center">
            <v-btn fab outlined x-small>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ getWords("side_add") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-for="item in dataSet.organizations" :key="item.title">
          <v-avatar color="pink" size="30" class="mr-8">n</v-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon class="ma-0">
            <v-menu offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-card min-width="200">
                <v-list>
                  <v-list-item>
                    <v-list-item-title>編集</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>表示</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>削除</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {
  getUserVocabulariesList,
  getUserNamespacesList,
} from "../plugins/firebase";
import words from "../assets/localewords.json";

export default {
  name: "SideMenu",
  props: ["mainPage"],
  data: () => ({
    dataSet: {
      vocabularies: [],
      schemas: [],
      terms: [],
      namespaces: [],
      organizations: [],
    },
  }),
  created() {
    if (this.isLoggedIn) {
      this.initData();
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.login;
    },
    getVocabularies() {
      return this.$store.state.vocabularies;
    },
    getNamespaces() {
      return this.$store.state.namespaces;
    },
    getLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    isLoggedIn: function (to) {
      if (to === true) {
        this.initData();
      } else {
        // this.$router.push({ name: "Login" }).catch((err) => console.log(err));
      }
    },
    getVocabularies: function () {
      this.dataSet.vocabularies = this.getVocabularies;
    },
    getNamespaces: function () {
      this.dataSet.namespaces = this.getNamespaces;
    },
  },
  methods: {
    async initData() {
      console.log(this.mainPage);
      this.dataSet.vocabularies = this.getVocabularies;
      if (this.dataSet.vocabularies.length === 0) {
        const data = await getUserVocabulariesList();
        this.$store.dispatch("commitVocabularies", data);
      }
      if (this.dataSet.namespaces.length === 0) {
        const data = await getUserNamespacesList();
        this.$store.dispatch("commitNamespaces", data);
      }
    },
    getWords (key) {
      let lang = this.getLanguage;
      if (lang !== "en" && lang !== "ja") {
        lang = "en";
      }
      const word = words[key] ? words[key][lang] : "";
      return word;
    },
    getTitle(item) {
      if (item["dct:title"].ja !== "") {
        return item["dct:title"].ja;
      } else {
        return item["dct:title"].en;
      }
    },
    addBtnPressed(type) {
      if (type === "vocabularies") {
        this.$router
          .push({ name: "Create" })
          .catch((err) => console.log(err));
      } else if (type === "namespaces") {
        this.$router
          .push({ name: "NameSpacesCreate" })
          .catch((err) => console.log(err));
      }
    },
    editBtnPressed(e) {
      const identifier = e.target.id;
      this.$store.dispatch("commitEditParams", {
        mode: "vocabularies",
        id: identifier,
      });
      if (this.$route.name !== "Edit") {
        this.$router
          .push({ name: "Edit" })
          .catch((err) => console.log(err));
      }
    },
    editNBtnPressed(e) {
      const identifier = e.target.id;
      this.$store.dispatch("commitEditParams", {
        mode: "namespaces",
        id: identifier,
      });
      if (this.$route.name !== "NameSpacesEdit") {
        this.$router
          .push({ name: "NameSpacesEdit" })
          .catch((err) => console.log(err));
      }
    },
    isSelectedV(item) {
      if (this.$route.name !== "Edit") {
        return false;
      }
      if (this.$store.state.editParams.id === item["dcat:identifier"]) {
        return true;
      }
      return false;
    },
    isSelectedN(item) {
      if (this.$route.name !== "NameSpacesEdit") {
        return false;
      }
      if (this.$store.state.editParams.id === item.id) {
        return true;
      }
      return false;
    },
  },
};
</script>
